<template lang="pug">
.free-page
  img.intro-img(src="../assets/free/free-receive.png")
  .confirm-button-section
    .confirm-button(:class="{ disabled: receiveBtnState }", @click="onReceive") {{ receiveBtnState ? '领取成功' : '免费领取' }}
  Popup(
    v-model:show="visible",
    position="bottom",
    round,
  )
    .header
      .select-title
      .close-icon(@click="visible = false")
    .content
      .stage-select-comp
        .stage-section
          .stage-name 请填写报名手机号
          .input-box
            Field.input(
              type="tel"
              maxlength="11",
              placeholder="请输入手机号",
              v-model="phone",
            )
        .stage-section(v-for="(stages, i) in stageList")
          .stage-name {{ i === 0 ? '年级' : ' ' }}
          .grade-name-section
            .grade-name(v-for="grade in stages",
              :class="{ selected: selectedGrade && selectedGrade.id === grade.id }",
              @click="onSelect('grade', grade)") {{grade.name}}
        .stage-section
          .stage-name 版本
          .grade-name-section
            .grade-name(
              v-for="item in selectedGrade?.versions ?? []",
              :class="{ selected: selectedVersion === item }"
              @click="selectedVersion = item"
            ) {{item}}
        .confirm-button-section.stage-button-section
          .confirm-button(
            :class="{ disabled: !signBtnState }", @click="onReceiveSubmit") 免费领取
VLoading(v-if="loading")
</template>

<script setup>
import {
  reactive, ref, onMounted, computed,
} from 'vue'
import { Popup, showDialog, Field } from 'vant'
import { stageKeys } from '@/consts'
import services from '@/services/parents'
import VLoading from '@/components/parents/VLoading.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const visible = ref(false)
const selectedGrade = ref({})
const selectedVersion = ref('人教版')
const phone = ref('')
const stageList = ref([])
const receiveBtnState = ref(true) // false未领取，true领取成功
const loading = ref(false)

const signBtnState = computed(() => {
  const regex = /^1\d{10}$/; // 以1开头，后面跟10个数字
  return regex.test(phone.value) && selectedVersion.value;
})

console.log(route, 'route');
function getSignUpInfo() {
  services.getSignUpInfo(100).then((res) => {
    if (res.code === 0) {
      console.log(res.data);
      receiveBtnState.value = !!res.data?.id
    }
  })
}

function onReceive() {
  // visible.value = true
  if (!receiveBtnState.value) {
    visible.value = true
  }
}

function onSelect(type, value) {
  if (type === 'grade' && value.id !== selectedGrade.value.id) {
    selectedGrade.value = value
    selectedVersion.value = value?.versions?.[0]
  }
}

// 领取的提交接口
function onReceiveSubmit() {
  if (!signBtnState.value) {
    return
  }
  loading.value = true
  services.signUpFreeOrder({
    goodsId: 100,
    grade: selectedGrade.value.id,
    phone: phone.value,
    version: selectedVersion.value,
    channel: route.query.channel,
  }).then((res) => {
    const encryptPhone = `${phone.value.slice(0, 3)}****${phone.value.slice(7, 11)}`
    loading.value = false
    if (res.code === 0) {
      showDialog({
        title: '领取成功',
        message: `规划师会在72小时内联系您,请保持${encryptPhone}手机畅通`,
        confirmButtonText: '我知道了',
        confirmButtonColor: '#FF584D',
      }).then(() => {
        // on close
      });
      receiveBtnState.value = true
      visible.value = false
    }
  }).catch(() => {
    loading.value = false
  })
}

onMounted(() => {
  getSignUpInfo()
  services.getMinProgramCourseVersionGradeList(100)
    .then(({ code, data }) => {
      if (code === 0) {
        const versionList = data.map((item) => ({
          ...item,
          id: item.grade,
          name: stageKeys[item.grade],
        }))
        const pupil = versionList.filter((item) => item.grade.includes('pupil'))
        const junior = versionList.filter((item) => item.grade.includes('junior'))
        // 默认选中三年级
        selectedGrade.value = pupil.find((item) => item.id === 'learner_pupil_three') ?? {}
        stageList.value = [pupil, junior]
      }
    })
})

</script>

<style lang="stylus" scoped>
placeholder()
  &::-webkit-input-placeholder
    {block}
  &:-moz-placeholder
    {block}
  &::-moz-placeholder
    {block}
  &:-ms-input-placeholder
    {block}
.free-page
  width 3.9rem
  margin 0 auto
  padding-bottom 1.24rem
.intro-img
  display block
  width 100%
.confirm-button-section
  position fixed
  bottom 0
  background #fff
  width 3.9rem
  box-sizing border-box
  z-index 100
  padding 0.2rem 0.24rem 0.54rem
.stage-section
  padding 0 0.2rem
.stage-name
  font-weight 500
  font-size 0.18rem
  line-height 0.28rem
  color #1f1f1f
  margin-bottom 0.09rem
.grade-name-section
  display grid
  grid-template-columns: repeat(3, 30%);
  // justify-content space-between
  // flex-wrap wrap
.grade-name
  background #FAFAFA
  border-radius 1rem
  padding 0.1rem 0
  width 100%
  text-align center
  color #8C8C8C
  font-size 0.14rem
  line-height 0.2rem
  font-weight 500
  margin-bottom 0.18rem
  &.selected
    background #FF333A
    color #fff
.list-item
  position relative
  padding 0.24rem 0.2rem
  font-weight 500
  font-size 0.16rem
  line-height 0.24rem
  color #3a3a3a
.list-item:not(:first-child):before
  content ''
  display block
  height 1px
  width 100%
  transform scaleY(0.5)
  background #E7E7E7
  position absolute
  top 0
  left 0
.select-content
  flex 1
  overflow auto
.list-item
  padding-right 0.5rem
  &.checked
    background url('~@/assets/parents/ic_checked.png') 3.5rem  50% / 0.2rem 0.2rem no-repeat
.stage-button-section
  position relative
  padding-left 0.24rem
  padding-right 0.24rem
  &::before
    content ''
    display block
    width 100%
    height 1px
    background #e7e7e7
    transform scaleY(0.5)
    position absolute
    top 0
    left 0
.input-box
  flex 1
  margin-bottom 0.16rem
  > .van-cell
    padding 0
.input
  /deep/ input
    font-size 0.16rem
    border none
    box-shadow none
    outline none
    padding 0
    margin 0
    -webkit-appearance none
    border-radius 0
    background transparent
    color #3a3a3a
    caret-color #FF333A
    width 100%
    min-width 0
    +placeholder()
      color #BFBFBF
      font-size 0.16rem
      line-height 0.24rem
      color #bfbfbf
.header
  position relative
  text-align center
  font-size 0.18rem
  line-height 0.2rem
  color #3a3a3a
  font-weight 500
  padding 0.24rem
.close-icon
  width 0.2rem
  height @width
  background url('~@/assets/parents/ic_close.png') 0 0 / contain no-repeat
  position absolute
  right 0.2rem
  top 0.24rem
</style>
